import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../../../../../../Components'
import { Checkbox } from '../../../../../../../../../Components/FormComponents/Checkbox'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const UninviteBudgetGroups: React.FC<IProps> = ({
  uninvitedBudgetGroups,
  onSelectedBudgetGroup,
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className={Styles.budgetGroupsWrapper}>
      {uninvitedBudgetGroups.map((budgetGroup) => {
        return (
          <div key={budgetGroup.id} className={Styles.row}>
            <div className={Styles.cell}>
              <Checkbox
                id={budgetGroup.id}
                label={budgetGroup.name}
                checked={false}
                onChange={(checked): void => onSelectedBudgetGroup(checked, budgetGroup)}
              />
            </div>

            <div className={Styles.cell}>
              <Typography variant="body-2">
                {t('{{number}} employees', {
                  number: budgetGroup.employeeCount,
                })}
              </Typography>
            </div>
          </div>
        )
      })}
    </div>
  )
}
