import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ConfirmModal, SideDrawer, Typography } from '../../../../../../../Components'
import { Sheet } from '../../../../../../../Components/SideDrawer/components/Sheet'
import { useTranslationDecorator } from '../../../../../../../Hooks/useTranslationDecorator'
import { formatDateString } from '../../../../../../../Lib/Formatters/DateFormatter'
import { GetBudgetGroupsDocument, useCreateWrpmSurveyMutation } from '../../../../../../../Lib/graphql'
import { LayoutContext } from '../../../../../../../Providers/LayoutProvider'
import { IBudgetGroup, IBudgetGroupWithoutSurvey, IBudgetGroupWithSurvey } from '../../../../interfaces'
import { InvitedBudgetGroups, UninviteBudgetGroups } from './Components'
import { IProps, TInvitedBudgetGroups } from './interfaces'
import Styles from './styles.module.scss'

export const InviteBudgetGroups: React.FC<IProps> = ({ onClose, referenceDate, budgetGroups, year }): JSX.Element => {
  const { t } = useTranslation()
  const { locale } = useTranslationDecorator()
  const { organizationId } = useContext(LayoutContext) // FIXME: how to inform the user when there is no organization_id?
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
  const [selectedBudgetGroups, setSelectedBudgetGroups] = useState<Array<string>>([])
  const [createWrpmSurvey] = useCreateWrpmSurveyMutation()

  const handleChange = (checked: boolean, budgetGroup: IBudgetGroup): void => {
    const currentBudgetGroups = selectedBudgetGroups

    if (checked) currentBudgetGroups.push(budgetGroup.name)
    else {
      const index = currentBudgetGroups.indexOf(budgetGroup.name)
      currentBudgetGroups.splice(index, 1)
    }

    setSelectedBudgetGroups(currentBudgetGroups)
    const budgetGroupsChosen = currentBudgetGroups.length > 0
    setButtonDisabled(!budgetGroupsChosen)
  }

  const sendInvitation = (): void => {
    createWrpmSurvey({
      variables: {
        budgetGroupNames: selectedBudgetGroups,
        organizationId: `${organizationId}`,
        referenceDate: formatDateString(referenceDate, 'dd-MM-yyyy', locale),
      },
      refetchQueries: [
        {
          query: GetBudgetGroupsDocument,
          variables: { organizationId, forYear: year },
        },
      ],
    })
      .then(() => {
        // FIXME: how to inform the user if the backend answers one or more survey's weren't created?
        setShowConfirmModal(false)
        onClose()
      })
      .catch(() => {
        // FIXME: how to inform the user if something went very wrong?
        onClose()
      })
  }

  const uninvitedBudgetGroups = budgetGroups.filter(
    ({ openWrpmSurvey }) => !openWrpmSurvey?.inviteSentAt
  ) as IBudgetGroupWithoutSurvey[]

  const invitedBudgetGroups = (budgetGroups as IBudgetGroupWithSurvey[])
    .filter(({ openWrpmSurvey }) => !!openWrpmSurvey?.inviteSentAt)
    .sort(
      // eslint-disable-next-line @getify/proper-arrows/params
      (a, b) => new Date(b.openWrpmSurvey.inviteSentAt).getTime() - new Date(a.openWrpmSurvey.inviteSentAt).getTime()
    )
    .reduce((acc, budgetGroup) => {
      if (budgetGroup?.openWrpmSurvey?.inviteSentAt) {
        const key = budgetGroup.openWrpmSurvey.inviteSentAt.split(' ')[0]

        if (acc[key]) acc[key].push(budgetGroup)
        else acc[key] = [budgetGroup]
      }

      return acc
    }, {} as TInvitedBudgetGroups)

  return (
    <SideDrawer title={t('Invite employees')} size="lg" onClose={onClose}>
      <Sheet>
        <div>
          <Typography variant="body-2" className={Styles.header}>
            {t('Select the budget groups below whose employees you want to send an automatic email invitation to.')}
          </Typography>

          <UninviteBudgetGroups uninvitedBudgetGroups={uninvitedBudgetGroups} onSelectedBudgetGroup={handleChange} />

          <div className={Styles.buttonWrapper}>
            <Button
              size="medium"
              onClick={(): void => setShowConfirmModal(!showConfirmModal)}
              className={Styles.button}
              disabled={buttonDisabled}
            >
              {t('Send invitation')}
            </Button>
          </div>

          <InvitedBudgetGroups invitedBudgetGroups={invitedBudgetGroups} />

          {showConfirmModal && (
            <ConfirmModal
              content={t('Send invitation email')}
              description={t('Send an invitation email to all invited employees within the selected budget groups.')}
              confirmButtonProps={{ onClick: sendInvitation, content: t('Send invitation') }}
              onCancel={(): void => setShowConfirmModal(false)}
            />
          )}
        </div>
      </Sheet>
    </SideDrawer>
  )
}
