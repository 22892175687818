import { ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IGetWrpmQuery, useGetWrpmQuery } from '../../../../Lib/graphql'
import { transformWRPMData } from '../helpers'
import { ITransformedWRPMRow, IWRPMTableRow } from '../interfaces'

export const useWrpm = (
  organizationId: string,
  forYear: number,
  budgetGroups: string[],
  getKindCellContent: (item: ITransformedWRPMRow) => ReactNode
): {
  wrpmData: IGetWrpmQuery | undefined
  tableData: IWRPMTableRow[]
} => {
  const { t } = useTranslation()

  const {
    data: wrpmData,
    startPolling,
    stopPolling,
  } = useGetWrpmQuery({
    variables: {
      organizationId,
      forYear,
      budgetGroupNames: budgetGroups,
    },
    skip: !organizationId || !budgetGroups.length,
  })

  const createTableData = (transformedWRPMData: ITransformedWRPMRow[]): IWRPMTableRow[] => {
    return transformedWRPMData.map((item): IWRPMTableRow => {
      return {
        id: item.id,
        kind: getKindCellContent(item),
        commute_kms: `${item.commute_kms.toLocaleString('nl')} ${t('km')}`,
        commute_co2: `${item.commute_co2.toLocaleString('nl')} ${t('gram')}`,
        business_kms: `${item.business_kms.toLocaleString('nl')} ${t('km')}`,
        business_co2: `${item.business_co2.toLocaleString('nl')} ${t('gram')}`,
      }
    })
  }

  const tableData = useMemo(() => {
    if (!wrpmData) return []

    return createTableData(transformWRPMData(wrpmData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrpmData])

  useEffect(() => {
    startPolling(5000)

    if (wrpmData?.reisbalansV2?.wrpm) stopPolling()

    return (): void => {
      stopPolling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrpmData])

  return {
    wrpmData,
    tableData,
  }
}
