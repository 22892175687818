import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../Components/Button'
import { EditBudgetGroupSource } from '../EditBudgetGroupSource'
import { IProps } from './interfaces'

export const EditBudgetGroupButton: React.FC<IProps> = ({
  organizationId,
  year,
  budgetGroup,
  wrpmData,
}): JSX.Element => {
  const { t } = useTranslation()
  const [showEdit, setShowEdit] = useState<boolean>(false)

  return (
    <React.Fragment>
      <Button variant="primary" size="medium" onClick={(): void => setShowEdit(true)}>
        {t('Edit data')}
      </Button>

      {showEdit && (
        <EditBudgetGroupSource
          budgetGroup={budgetGroup}
          organizationId={organizationId}
          data={wrpmData}
          year={year}
          onClose={(): void => setShowEdit(false)}
        />
      )}
    </React.Fragment>
  )
}
