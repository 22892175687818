import { t } from 'i18next'

import { TIconName } from '../../../Components/Icon/iconNames'
import { ITableConfig } from '../../../Components/Table/interfaces'
import { IGetWrpmQuery, IV2BudgetGroup, IWrpmModalityEnum } from '../../../Lib/graphql'
import { IBudgetGroupOption, ITransformedWRPMRow, IYearOptions, TStatisticsForBudgetGroup } from './interfaces'

export const getLastDateForYear = (year: number): Date => new Date(`12/31/${year}`)

export const getTableConfig = (): ITableConfig => {
  return {
    kind: {
      name: t('Kind'),
      width: '300px',
    },
    commute_kms: {
      name: t('Commute'),
      width: '200px',
    },
    commute_co2: {
      name: t('Commute CO2'),
      width: '200px',
    },
    business_kms: {
      name: t('Business'),
      width: '200px',
    },
    business_co2: {
      name: t('Business CO2'),
      width: '200px',
    },
  }
}

export const transformWRPMData = (WRPMData: IGetWrpmQuery): ITransformedWRPMRow[] => {
  const { wrpm } = WRPMData.reisbalansV2

  if (!wrpm || !wrpm.length) return []

  const transformedWRPMData = wrpm.map((item): ITransformedWRPMRow => {
    return {
      id: item?.modality?.value || '',
      kind: item?.modality?.value || null,
      humanized: item?.modality?.humanized || '',
      commute_kms: parseInt(item?.statsPerModalityCommute?.totalKms, 10) || 0,
      commute_co2: parseInt(item?.statsPerModalityCommute?.totalCo2, 10) || 0,
      business_kms: parseInt(item?.statsPerModalityBusiness?.totalKms, 10) || 0,
      business_co2: parseInt(item?.statsPerModalityBusiness?.totalCo2, 10) || 0,
    }
  })

  const cumulativeRow = transformedWRPMData.reduce(
    (acc, value): ITransformedWRPMRow => {
      acc.commute_kms += value.commute_kms
      acc.commute_co2 += value.commute_co2
      acc.business_kms += value.business_kms
      acc.business_co2 += value.business_co2

      return acc
    },
    {
      id: 'total',
      kind: null,
      humanized: t('Total'),
      commute_kms: 0,
      commute_co2: 0,
      business_kms: 0,
      business_co2: 0,
    }
  )

  return [cumulativeRow, ...transformedWRPMData]
}

export const mapModalityEnumToIconName = (value: IWrpmModalityEnum): TIconName => {
  switch (value) {
    case IWrpmModalityEnum.CAR_PETROL:
    case IWrpmModalityEnum.CAR_DIESEL:
    case IWrpmModalityEnum.CAR_HYBRID:
    case IWrpmModalityEnum.CAR_ELECTRIC:
    case IWrpmModalityEnum.CAR_OTHER:
      return 'car'

    case IWrpmModalityEnum.MOTORCYCLE_PETROL:
    case IWrpmModalityEnum.MOTORCYCLE_ELECTRIC:
      return 'motorcycle'

    case IWrpmModalityEnum.SCOOTER_PETROL:
    case IWrpmModalityEnum.SCOOTER_ELECTRIC:
      return 'scooter'

    case IWrpmModalityEnum.LEGS_OR_BICYCLE:
      return 'bicycle'

    case IWrpmModalityEnum.OV:
      return 'public-transport'

    default:
      return 'receipt'
  }
}

export const calculateSample = (numEmployees: number): number => {
  // For more info regarding the constants below visit the following link https://mobiliteitsfabriek.atlassian.net/wiki/x/BYD9BAE
  const z = 1.96
  const N = numEmployees
  const p = 0.5
  const F = 0.05

  return Math.ceil((N * z ** 2 * p * (1 - p)) / (z ** 2 * p * (1 - p) + (N - 1) * F ** 2))
}

export const calculateStatisticsForBudgetGroup = (
  respondents: number,
  invitees: number
): TStatisticsForBudgetGroup | null => {
  if (!invitees) return null

  const sample = calculateSample(invitees)

  return {
    thresholdMet: respondents >= sample,
    actualPercentage: Math.round((respondents * 100) / invitees),
    goalPercentage: Math.round((sample * 100) / invitees),
  }
}

export const generateYearArray = (): IYearOptions[] => {
  const minimumYear = 2024
  const currentYear = new Date().getFullYear() // On january 1st the new year needs to be added to the array
  const arrayYear = []

  for (let i = minimumYear; i <= currentYear; i += 1) arrayYear.push({ label: i.toString(), value: i })

  return arrayYear
}

export const makeBudgetGroupOptions = (budgetGroups: IV2BudgetGroup[]): IBudgetGroupOption[] => {
  if (!budgetGroups.length) return []

  const allOption: IBudgetGroupOption = {
    label: t('All budgetgroups'),
    value: null,
  }

  const options = budgetGroups.map((budgetGroup): IBudgetGroupOption => {
    return {
      label: budgetGroup.name!,
      value: budgetGroup,
    }
  })

  return [allOption, ...options]
}
