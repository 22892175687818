import { endOfDay } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../../Components'
import { Radio } from '../../../../../Components/FormComponents/Radio'
import { Option } from '../../../../../Components/FormComponents/Radio/Components/Option'
import { useTranslationDecorator } from '../../../../../Hooks/useTranslationDecorator'
import { formatDateString } from '../../../../../Lib/Formatters/DateFormatter'
import { getLastDateForYear } from '../../helpers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ReferenceDate: React.FC<IProps> = ({ forYear, selectedDate, fixedDate, onChange }): JSX.Element => {
  const { t } = useTranslation()
  const { locale } = useTranslationDecorator()
  const dateFormat = 'dd MMMM yyyy'
  const options = [
    {
      label: formatDateString(getLastDateForYear(forYear), dateFormat, locale),
      value: endOfDay(getLastDateForYear(forYear)).toString(),
    },
    {
      label: t(`{{ date }} (today)`, { date: formatDateString(new Date(), dateFormat, locale) }),
      value: endOfDay(new Date()).toString(),
    },
  ]

  if (fixedDate) {
    return (
      <Typography variant="body-2">
        {t('Budget groups are set on {{ date }}', { date: formatDateString(fixedDate, dateFormat, locale) })}
      </Typography>
    )
  }

  return (
    <Radio
      id="referenceDate"
      label={t('Budget groups as set on:')}
      className={Styles.radio}
      onChange={(value: string): void => onChange(new Date(value))}
      value={selectedDate ? endOfDay(selectedDate).toString() : undefined}
    >
      {options.map(({ label, value }, index) => (
        <Option label={label} value={value} key={index} />
      ))}
    </Radio>
  )
}
