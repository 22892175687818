import { format } from 'date-fns'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Button } from '../../../../../Components/Button'
import { ConfirmModal } from '../../../../../Components/ConfirmModal'
import { useExportWrpmLazyQuery } from '../../../../../Lib/graphql'
import { IProps } from './interfaces'

export const ExportButton: React.FC<IProps> = ({ organizationId, forYear }): JSX.Element => {
  const { t } = useTranslation()
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [exportWrpmQuery] = useExportWrpmLazyQuery()

  const downloadAsFile = (base64: string): void => {
    const linkSource = `data:application/octet-stream;base64,${base64}`
    const downloadLink = document.createElement('a')

    downloadLink.href = linkSource
    downloadLink.download = `wrpm-export-${format(new Date(), 'yyyy-MM-dd')}.xlsx`
    downloadLink.click()
  }

  const handleClick = (): void => {
    exportWrpmQuery({ variables: { organizationId, forYear } })
      .then((response) => {
        const base64 = response.data?.reisbalansV2?.v2Organization?.wrpmExportFile?.xlsFile

        if (base64) downloadAsFile(base64)

        setShowConfirmModal(false)
      })
      .catch(() => {
        toast.error(t('An error has occurred'))
      })
  }

  return (
    <React.Fragment>
      <Button variant="primary" size="large" icon="download" onClick={(): void => setShowConfirmModal(true)}>
        {t('Export')}
      </Button>

      {showConfirmModal && (
        <ConfirmModal
          content={t('Export overview')}
          description={t('The complete overview will be exported to an Excel file.')}
          cancelContent={t('Cancel')}
          onCancel={(): void => setShowConfirmModal(false)}
          confirmButtonProps={{
            content: t('Export_long'),
            onClick: handleClick,
          }}
        />
      )}
    </React.Fragment>
  )
}
